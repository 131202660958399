import { n as normalizeComponent, E as ExportTypes } from "./index.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "upload-details" }, [_c("a-row", { attrs: { "type": "flex", "justify": "space-between", "align": "middle" } }, [_c("h3", { staticClass: "legend-title" }, [_vm._v("Upload " + _vm._s(_vm.title))]), _vm.hasStatus ? _c("a-badge", { staticClass: "status-badge" }, [_vm._v(_vm._s(_vm.text))]) : _vm._e()], 1), _vm.createdBy ? _c("p", { staticClass: "modified-by" }, [_vm._v(" " + _vm._s(this.$moment(_vm.errorFiles.createdAt).format("DD/MM/YYYY hh:mm")) + " by " + _vm._s(_vm.createdBy.firstName) + " " + _vm._s(_vm.createdBy.lastName) + " ")]) : _vm._e(), _vm.hasDescription && !_vm.errorFiles.errors.length ? _c("p", { staticClass: "error-message mt-3" }, [_vm._v(" There are some errors. Your files haven't been uploaded yet. Please check these problems below. ")]) : _vm._e(), _c("ul", { staticClass: "error-files" }, [_c("li", { staticClass: "error-files-item" }, [_c("div", { staticClass: "file-name" }, [_c("p", [_vm._v("File: " + _vm._s(_vm.errorFiles.fileName))]), _vm.failed ? _c("span", [_vm._v(_vm._s("Failed: " + _vm.errorFiles.failed))]) : !_vm.errorFiles.errors.length ? _c("span", [_c("a-icon", { attrs: { "type": "check-circle", "theme": "filled" } }), _vm._v("Succeeded ")], 1) : _vm._e()]), _c("ul", { staticClass: "errors" }, _vm._l(_vm.errorFiles.errors, function(error, index) {
    return _c("li", { key: index }, [_vm._v(_vm._s(error))]);
  }), 0)])]), _vm.hasStatus ? _c("p", { staticClass: "upload-status" }, [_vm._v("Upload files are processing...")]) : _vm._e()], 1);
};
var staticRenderFns$2 = [];
var UploadDetails_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$2 = {
  name: "UploadDetails",
  inject: ["apiUrl"],
  props: {
    errorFiles: {
      type: Object,
      default: () => ({})
    },
    hasDescription: {
      type: Boolean,
      default: true
    },
    hasStatus: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      createdBy: {
        firstName: "",
        lastName: ""
      }
    };
  },
  computed: {
    text() {
      const { status } = this;
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
    failed() {
      return this.errorFiles.failed && this.errorFiles.failed.split("/")[0] > 0;
    }
  },
  mounted() {
    this.fetchFullNameCreateBy(this.errorFiles.createdBy);
  },
  methods: {
    async fetchFullNameCreateBy(createdBy) {
      const { data } = await this.axios.get(`${"#{VUE_APP_API_URL}#"}/identities/ad/users/${createdBy}`);
      this.createdBy.firstName = data.firstName;
      this.createdBy.lastName = data.lastName;
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "5813d9ad", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var UploadDetails = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "upload-list" }, [_c("p", { staticClass: "title-upload" }, [_vm._v("Download the " + _vm._s(_vm.title.name) + " upload template")]), _c("div", { staticClass: "template-file" }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 12, "xl": 18 } }, [_c("a-icon", { attrs: { "type": "file-zip", "theme": "filled" } }), _vm._v(" "), _c("a", { attrs: { "href": _vm.apiUrl + "/files/assets/" + _vm.fileName + "-Upload-Template." + _vm.title.templateType, "download": "" } }, [_vm._v(_vm._s(_vm.fileName) + "-Upload-Template." + _vm._s(_vm.title.templateType))])], 1), _c("a-col", { staticStyle: { "text-align": "right" }, attrs: { "lg": 24, "xl": 6 } }, [_c("a-icon", { attrs: { "type": "cloud-download" } })], 1)], 1)], 1), _c("div", { staticClass: "drag-template mt-5 mb-5" }, [_c("a-upload-dragger", { attrs: { "name": "file", "multiple": _vm.multiple, "accept": "." + _vm.title.templateType, "show-upload-list": false, "before-upload": _vm.onBeforeUpload } }, [_c("p", { staticClass: "ant-upload-drag-icon" }, [_c("a-icon", { attrs: { "type": "inbox" } })], 1), _c("p", { staticClass: "ant-upload-text" }, [_c("span", [_vm._v("Click here")]), _vm._v(" or drag file to this area to upload ")]), _c("p", { staticClass: "ant-upload-hint" }, [_vm._v(" Single upload file should not be more than 10MB. Support only ." + _vm._s(_vm.title.templateType) + " file. ")])])], 1), _vm.files.length ? _c("div", { staticClass: "files-uploaded-container" }, [_c("p", { staticClass: "title-upload" }, [_vm._v("Upload files")]), _c("ul", { staticClass: "files" }, _vm._l(_vm.files, function(file, index) {
    return _c("li", { key: index }, [_c("a-icon", { attrs: { "type": "file-excel" } }), _c("a", [_vm._v(_vm._s(file.name))]), !_vm.loading ? _c("a-icon", { attrs: { "type": "close" }, on: { "click": function($event) {
      return _vm.files.splice(index, 1);
    } } }) : _vm._e()], 1);
  }), 0)]) : _vm._e(), _c("portal", { attrs: { "to": "upload-product-modal-footer" } }, [_c("a-button", { key: "back", on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { key: "submit", attrs: { "disabled": !_vm.files.length, "type": "primary", "loading": _vm.loading }, on: { "click": _vm.onUploadClick } }, [_vm._v(" Upload "), _vm.files.length ? [_vm._v("(" + _vm._s(_vm.files.length) + ")")] : _vm._e()], 2)], 1)], 1);
};
var staticRenderFns$1 = [];
var UploadList_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "UploadList",
  props: {
    title: {
      type: Object,
      default: null
    },
    files: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onBeforeUpload: {
      type: Function,
      default: () => {
      }
    },
    onCancelClick: {
      type: Function,
      default: () => {
      }
    },
    onUploadClick: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1
    };
  },
  computed: {
    fileName() {
      return this.title.name.replace(/[^A-Z0-9]+/gi, "-");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0d7e6876", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var UploadList = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "upload-product" }, [_c("a-modal", { attrs: { "title": !_vm.hasErrors ? "Upload " + _vm.title.name : "Upload Details", "visible": _vm.visible, "after-close": _vm.handleCancel }, on: { "cancel": _vm.handleCancel }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
    return [_c("div", [_c("portal-target", { attrs: { "name": "upload-product-modal-footer" } })], 1)];
  }, proxy: true }]) }, [!_vm.hasErrors ? _c("upload-list", { attrs: { "multiple": _vm.multiple, "files": _vm.files, "loading": _vm.loading, "title": _vm.title, "on-before-upload": _vm.beforeUpload, "on-cancel-click": _vm.handleCancel, "on-upload-click": _vm.handleUpload } }) : _c("upload-details", { attrs: { "title": _vm.title.name, "error-files": _vm.errorFiles, "status": "processing", "on-cancel-click": _vm.handleCancel, "type": _vm.type } })], 1)], 1);
};
var staticRenderFns = [];
const { CSV_MIME_TYPES, EXCEL_MIME_TYPES, UPLOAD_TYPE } = ExportTypes;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "UploadForm",
  components: {
    UploadDetails,
    UploadList
  },
  inject: ["crud"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    }
  },
  provide() {
    return {
      apiUrl
    };
  },
  data() {
    return {
      apiUrl,
      files: [],
      errorFiles: {},
      loading: false
    };
  },
  computed: {
    hasErrors() {
      return this.errorFiles.errors && this.errorFiles.errors.length > 0;
    },
    title() {
      return UPLOAD_TYPE.find((e) => e.type == this.type);
    }
  },
  methods: {
    checkFileType(file, typeCheck) {
      let fileTypes = typeCheck == "csv" ? CSV_MIME_TYPES : EXCEL_MIME_TYPES;
      const extension = file.name.split(".").pop();
      const { type } = file;
      return fileTypes.includes(type) && extension == typeCheck;
    },
    handleUpload() {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file);
      });
      this.loading = true;
      this.axios.post(apiUrl + this.title.url, formData).then((res) => {
        this.loading = false;
        const { data } = res;
        if (data.errors && data.errors.length) {
          this.errorFiles = data;
          return;
        }
        this.crud.fetchList();
        this.handleCancel();
        this.$notification.success({ message: "Item(s) Uploaded" });
      }).catch((err) => {
        this.loading = false;
        this.$notification.error({
          message: this._.get(err, "response.data.message") || "An error occurred while uploading. Please check your file or contact the administrator."
        });
      });
    },
    beforeUpload(file) {
      if (!this.multiple || this.type == 2) {
        this.files = [];
      }
      if (this.checkFileType(file, this.title.templateType)) {
        this.files = [...this.files, file];
      } else {
        this.$notification.error({
          message: `${this.title.name} support only .${this.title.templateType} file format.`
        });
      }
      return false;
    },
    handleCancel() {
      if (!this.loading) {
        this.$emit("update:visible", false);
        setTimeout(() => {
          this.files = [];
          this.errorFiles = [];
        }, 200);
      } else {
        this.$notification.warning({
          message: "Upload file is being processed!"
        });
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var UploadForm = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { UploadForm as U, UploadDetails as a };
